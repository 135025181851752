import React from 'react'
import Layout from '../components/layout'
import Helmet from 'react-helmet'

const NotFoundPage = () =>
  <Layout>
    <Helmet title="File not found" />

    <section className="post">
      <header className="post-header">
        <h1 className="post-title">NOT FOUND</h1>
      </header>

      <p>You file you requested doesn't exist…</p>
    </section>

  </Layout>

export default NotFoundPage
